import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import Img from 'gatsby-image'
import InViewport from '@components/InViewport'
import styles from './styles'

const ConventionCard = ({
  data: {
    title,
    image,
    location,
    startDate,
    endDate
  },
  lang,
  className,
  ...rest
}) => {
  return (
    <InViewport
      className={cn(styles.card, className)}
      {...rest}
    >
      <Img fluid={image.asset.fluid} className={styles.image} placeholderClassName="gatsby-image-placeholder" />
      <div className={styles.info}>
        <span className={styles.location}>{location}</span>
        <span className={styles.date}>{startDate}{ endDate && " - " }{endDate}</span>
      </div>
      <p className={styles.title}>{ title[lang] ? title[lang] : title.en }</p>
    </InViewport>
  )
}

ConventionCard.propTypes = {
  data: PropTypes.shape({
    title: PropTypes.objectOf(PropTypes.string).isRequired,
    image: PropTypes.object.isRequired,
    location: PropTypes.string.isRequired,
    startDate: PropTypes.string.isRequired,
    endDate: PropTypes.string,
  }),
  lang: PropTypes.oneOf(['en', 'jp']),
  className: PropTypes.string,
}

ConventionCard.defaultProps = {
  data: {
    endDate: ''
  },
  lang: 'en',
  className: ''
}

export default ConventionCard
