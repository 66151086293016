import React from 'react'
import PropTypes from 'prop-types'
import cn from 'classnames'
import styles from './styles'

const CardList = ({ className, children }) => (
  <div className={cn(className, "wrapper")}>
    <div className="row -my-6">
      <div
        className={
          cn(
            styles.container,
            "col-12 lg:col-10 lg:offset-1"
          )
        }
      >
        <div className="row">
          { children }
        </div>
      </div>
    </div>
  </div>
)

CardList.propTypes = {
  className: PropTypes.string
}

CardList.defaultProps = {
  className: ''
}

export default CardList
