import React, { useContext } from 'react'
import { graphql } from 'gatsby'
import Layout from '@components/Layout'
import SEO from '@components/seo'
import PageTitle from '@components/PageTitle'
import CardList from '@components/CardList'
import ConventionCard from '@components/ConventionCard'
import LocaleContext from '@context/LocaleContext'
import localize from '@components/localize';

export const query = graphql`
  query {
    allSanityConvention(
      sort: {fields: startDate, order: DESC }
    ) {
      edges {
        node {
          id
          _type
          title {
            en
            jp
          }
          image {
            asset {
              _id
              _type
              fluid(maxWidth: 784) {
                ...GatsbySanityImageFluid_withWebp
              }
            }
          }
          location
          startDate(formatString: "MM/DD/YY")
          endDate(formatString: "MM/DD/YY")
        }
      }
    }
  }
`

const Conventions = ({
  data: { allSanityConvention },
  pageContext: { title, description, metaImage }
}) => {
  const { code } = useContext(LocaleContext)

 return (
  <Layout>
    <SEO title={title} description={description} image={metaImage?.asset.url} />
    <PageTitle>
      {title}
    </PageTitle>
    <div className="bg-white">
      <CardList className="pt-20 pb-10 md:pb-0">
        {allSanityConvention.edges.map((convention, index) => (
          <div key={convention.node.id} className="col-6 md:col-4">
            <ConventionCard
              key={convention.node.id}
              data={convention.node}
              lang={code}
            />
          </div>
        ))}
      </CardList>
    </div>
  </Layout>
 )
}

export default localize(Conventions)
